(function($) {

  $('.js-tab').on('hover', function(e) {
    e.preventDefault();
    var id = $(this).attr("href");
    var height = $(this).outerHeight();
     $(this).tab('show');
  }).on('click', function(e){
  	e.preventDefault();
  	var height = $(this).outerHeight();
  	var target = $('.js-tab-content').offset().top - height*2;
    $('html, body').animate({ scrollTop: target }, 500);
  });

   $('.js-tab-default').on('click', function(e) {
    e.preventDefault();
     $(this).tab('show');
     return false;
  });

})(jQuery);
