(function($) {

  function Vimeo(element) {
    this.$el = $(element);
  }

  Vimeo.prototype = {
    init: function() {
      var t = this;
      // Event fired to play vimeo click event
      this.$el.on('click', function() {
        var target = $(this).data('target');
        var source = $(this).attr('href');
        var ID = t.getVimeoID(source);
        var embed = "https://player.vimeo.com/video/" + ID + "?autoplay=1&title=0&byline=0&portrait=0";

        // Event to add the vimeo url
        // Param: Target modal element, Vimeo Player URL
        t.showVimeo(target, embed);

        // Event to detect if the modal was close 
        // Param: Target Modal Element
        t.hideVimeo(target);
      });
    },
    getVimeoID: function(url) {
      // Get the ID base from the vimeo URL
      var match = /vimeo.*\/(\d+)/i.exec(url);
      if (url) {
        return match[1];
      }
    },
    showVimeo: function(el, url) {
      // Add embed url to iframe
      // Param: Target Modal Element, Vimeo Player URL
      $(el + ' iframe').attr('src', url);
    },
    hideVimeo: function(el) {
      // Detect if modal is close
      // Param: Target Modal Element
      $(el).on('hide.bs.modal', function(e) {
        // reset value of iframe
        $(el + ' iframe').attr('src', ' ');
      });
    }
  };

  if ($('.js-vimeo').length) {
    var vimeo = new Vimeo('.js-vimeo');
    vimeo.init();
  }
})(jQuery);
