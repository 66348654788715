(function($) {
  var $body = $('body');
  var $svg = $('.js-svg');
  var $link = $svg.find('.svg-link');
  var $title = $('.svg-title-link');
  var $description = $link.next('.svg-description');
  var $listing = $('.section-svg').find('#svg-list');
  var $dismiss = $description.find('.close');
  var IN = 'in';
  var HOVER = 'hover';
  var NOSCROLL = 'noscroll';

  
  function dismiss() {
    $description.removeClass(IN);
    $body.removeClass(NOSCROLL);
  }

  $('.svg-title-link').detach().appendTo('.section-svg #svg-list');

  // Trigger  
  $link.on('click', function() {
    $(this).next().addClass(IN);
    $body.addClass(NOSCROLL);
    return false;
  });

  $title.on('click', function(e) {
    e.preventDefault();
    var id = $(this).attr('href');
    $(id).trigger('click');
  }).on('hover', function(e) {
    e.preventDefault();
    var id = $(this).attr('href');
    $(id).addClass(HOVER);
  });

  $title.mouseleave(function() {
   var id = $(this).attr('href');
    $(id).removeClass(HOVER);
  });

  // Dismiss Modal
  $body.mouseup(function(e) {
    if (!$description.is(e.target) && $description.has(e.target).length === 0 || e.target.nodeName === "BUTTON") {
      dismiss();
    }
  });

})(jQuery);
