(function($) {

  $('.js-gallery').slick({
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    fade: true,   
    cssEase: 'linear'
  });

  $('.js-avatars').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,

    dots: false,
    arrows: true
  });

})(jQuery);
