(function($) {

  // Hide Header on on scroll down
  var didScroll; 
  var lastScrollTop = 0;
  var delta = 5;
  var $header = $('.js-header');
  var $body = $('body');
  var UP = 'up';
  var DOWN = 'down';
  var navbarHeight = $header.outerHeight();

  $(window).scroll(function(event) {
    didScroll = true;
  });


  function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta){
      return;
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $header.removeClass(DOWN).addClass(UP);
      $body.removeClass('header-'+DOWN).addClass('header-'+UP);
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $header.removeClass(UP).addClass(DOWN);
        $body.removeClass('header-'+UP).addClass('header-'+DOWN);
      }
    }

    lastScrollTop = st;
  }

  setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);


})(jQuery);
