(function($) {

  $.fn.customerPopup = function(e, intWidth, intHeight, blnResize) {

    // Prevent default anchor event
    e.preventDefault();

    // Set values for window
    intWidth = intWidth || '500';
    intHeight = intHeight || '400';
    strResize = (blnResize ? 'yes' : 'no');

    // Set title and open popup with focus on it
    var strTitle = ((typeof this.attr('title') !== 'undefined') ? this.attr('title') : 'Social Share'),
      strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize,
      objWindow = window.open(this.attr('href'), strTitle, strParam).focus();
  };

  $.fn.copyToClipboard = function (e) {
    var $body = $('body');
    var $temp = $("<input>");
    var COPIED = 'copied';
    var $this = this;
    $body.append($temp);
    $temp.val($this.attr('href')).select();
    document.execCommand("copy");
    $temp.remove();

    // Add indicator
    $this.addClass(COPIED);
    setTimeout(function(){ $this.removeClass(COPIED); }, 3000);
  };
  
  // Opens Window
  $('.js-share').on("click", function(e) {
    $(this).customerPopup(e);
  });

  // Copy Clipboard
  $('.js-copy').on("click", function(e) {
    e.preventDefault();
    $(this).copyToClipboard(e);
  });

  
})(jQuery);
