(function($) {
    /*
     * Add Smooth Scrolling Transition */
    $('a[href^="#"]:not(.js-tab-default)').click(function(event) {
        event.preventDefault();

        var id = $(this).attr("href");
        var target = $(id).offset().top; 


        $('html, body').animate({ scrollTop: target }, 800);
    }); 

})(jQuery);
