(function($) {

  var $window = $(window);
  var $sidebar = $(".js-sticky");

  if ($sidebar.length) {
    var $sidebarHeight = $sidebar.innerHeight();
    var $footerOffsetTop = $(".js-sticky-stopper").offset().top - 120;
    var $sidebarOffset = $sidebar.offset();
    $window.scroll(function() {
      if ($window.scrollTop() > $sidebarOffset.top) {
        $sidebar.addClass("fixed");
      } else {
        $sidebar.removeClass("fixed");
      }
      if ($window.scrollTop() + $sidebarHeight > $footerOffsetTop) {
        $sidebar.css({ "top": -($window.scrollTop() + $sidebarHeight - $footerOffsetTop) });
      } else {
        $sidebar.css({ "top": "0" });
      }
    });
  }

})(jQuery);
